import * as api from "../Services/api.js";
import * as moment from "moment";
export const state = {
  file: "",
  formData: new FormData(),
  allBatches: [],
  currentBatch: [],
  providers: [],
  currentBatchId: "",
  providersForBatch: [],
  schoolOptions: [],
  assessmentOptions: [],
  scrollBy: 2000
};
export const mutations = {
  setFile(state, payload) {
    state.file = payload;
  },
  setAllBatches(state, payload) {
    state.allBatches = payload;
  },
  markVAction(state, payload) {
    var i;
    for (i = 0; i < state.currentBatch.length; i++) {
      if (state.currentBatch[i].importRowId == payload) {
        state.currentBatch[i].isVAction = true;
      }
    }
  },
  updateEvalDueDate(state, payload) {
    var i;
    for (i = 0; i < state.currentBatch.length; i++) {
      if (state.currentBatch[i].importRowId == payload.rowId) {
        state.currentBatch[i].evalDate = moment(payload.evalDueDate).format(
          "M/D/YYYY"
        );
      }
    }
  },
  updateScroll(state, payload) {
    state.scrollBy = payload;
  }
};
export const getters = {
  rowsToAssign: state => {
    return state.currentBatch.filter(
      b =>
        b.status == "New Evaluation" ||
        b.status == "New P Action Evaluation" ||
        b.status == "New Client"
    );
  },
  isProviderBlocked: state => id => {
    var provider = state.providers.filter(b => b.providerId == id);
    return provider[0].isBlocked;
  },
  //providersForBatch: state =>
  //{
  //  var provs = [];
  //  var i;
  //  for (i = 0; i < state.providers.length; i++)
  //  {
  //    var matches = state.currentBatch.filter(b => b.providerId == state.providers[i].providerId);
  //    if (matches.length > 0)
  //    {
  //      provs.push(state.providers[i]);
  //    }
  //  }
  //  return provs;
  //},
  rowsForProvider: state => providerId => {
    var rows = state.currentBatch.filter(b => b.providerId == providerId);
    return rows;
  }
};
export const actions = {
  async createRfa({ commit, state }) {
    commit(
      "uxModule/setLoaderMsg",
      "Processing batch. This may take a while...",
      {
        root: true
      }
    );

    commit("uxModule/setShowLoader", true, {
      root: true
    });
    //state.formData.append("listFile", state.file, state.file.name);

    //var response = await api.uploadRFA(state.formData);
    var response = await api.createRfa();
    console.log("in response in action");
    console.log(response);
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response);
    hiddenElement.target = "_blank";
    hiddenElement.download = "batchresult.txt";
    hiddenElement.click();

    var responseB = await api.getRfaBatches();
    state.allBatches = responseB;
    var i;
    for (i = 0; i < state.allBatches.length; i++) {
      state.allBatches[i].importDate = new Date(
        Date.parse(state.allBatches[i].importDate)
      ).toLocaleDateString("en-US");
    }

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },

  async getBatches({ commit, state }) {
    commit("uxModule/setLoaderMsg", "Loading imports...", {
      root: true
    });

    commit("uxModule/setShowLoader", true, {
      root: true
    });

    var response = await api.getRfaBatches();
    state.allBatches = response;
    var i;
    for (i = 0; i < state.allBatches.length; i++) {
      state.allBatches[i].importDate = new Date(
        Date.parse(state.allBatches[i].importDate)
      ).toLocaleDateString("en-US");
    }
    console.log("in response in action");
    console.log(response);

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async getBatchRows({ commit, state }, payload) {
    commit("uxModule/setLoaderMsg", "Loading import...", {
      root: true
    });

    commit("uxModule/setShowLoader", true, {
      root: true
    });

    var response = await api.getBatchRows(payload);
    state.currentBatchId = payload;
    state.currentBatch = response;
    var i;
    for (i = 0; i < state.currentBatch.length; i++) {
      state.currentBatch[i].studentName =
        state.currentBatch[i].lastName + ", " + state.currentBatch[i].firstName;

      state.currentBatch[i].dateOfBirth = new Date(
        Date.parse(state.currentBatch[i].dateOfBirth)
      ).toLocaleDateString("en-US");
      state.currentBatch[i].evalDate = new Date(
        Date.parse(state.currentBatch[i].evalDate)
      ).toLocaleDateString("en-US");
    }
    console.log("in response in action");
    console.log(response);

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async getBatchProviders({ commit, state }, payload) {
    commit("uxModule/setLoaderMsg", "Loading providers...", {
      root: true
    });

    commit("uxModule/setShowLoader", true, {
      root: true
    });

    var response = await api.getBatchProviders(payload);
    state.providers = response;
    console.log("finished fetching providers");

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async getDistinctBatchProviders({ commit, state }) {
    commit("uxModule/setLoaderMsg", "Loading providers...", {
      root: true
    });

    commit("uxModule/setShowLoader", true, {
      root: true
    });

    var response = await api.getDistinctBatchProviders(state.currentBatchId);
    state.providersForBatch = response;
    console.log("finished fetching providers");

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async getOptions({ commit, state }) {
    commit("uxModule/setLoaderMsg", "Loading options...", {
      root: true
    });

    commit("uxModule/setShowLoader", true, {
      root: true
    });

    var response = await api.getOptions();
    state.schoolOptions = response.schools;
    state.assessmentOptions = response.types;

    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async updateVAction({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, { root: true });
    var response = await api.updateVAction(payload).then(response => {
      // if (response.data == 1) {
      commit("markVAction", payload.rowId);
      // }
    });
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async updateEvalDueDate({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, { root: true });
    var response = await api.updateEvalDueDate(payload).then(response => {
      if (response == 1) {
        commit(
          "uxModule/setSnackbarMsg",
          "Evaluation due date has been updated!",
          {
            root: true
          }
        );
        commit("uxModule/setShowSnackbar", true, {
          root: true
        });
        commit("updateEvalDueDate", payload);
      }
    });
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },
  async updateHasConsent({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var response = await api.updateHasConsent(payload);
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },

  async updateComment({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var response = await api.updateComments(payload);
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },

  async updateProvider({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var isBlocked = false;
    var i;

    for (i = 0; i < state.providers.length; i++) {
      if (state.providers[i].providerId == payload.value) {
        if (state.providers[i].isBlocked) {
          isBlocked = true;
        }
      }
    }

    if (isBlocked) {
      var j;
      for (j = 0; j < state.currentBatch.length; j++) {
        if (state.currentBatch[j].importRowId == payload.id) {
          state.currentBatch[j].isProviderBlocked = true;
        }
      }
    }
    var response = await api.updateProvider(payload);
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    return 1;
  },

  async updateAction({ commit, state }, payload) {
    var j;
    for (j = 0; j < state.currentBatch.length; j++) {
      if (state.currentBatch[j].importRowId == payload.rowId) {
        if (state.currentBatch[j].evalDate == "Invalid Date") {
          commit(
            "uxModule/setSnackbarMsg",
            "Please input the evaluation due date before saving the action",
            {
              root: true
            }
          );
          commit("uxModule/setShowSnackbar", true, {
            root: true
          });
          return;
        }
      }
    }
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var response = await api.updateAction(payload);
    if (response == 1) {
      var i;
      for (i = 0; i < state.currentBatch.length; i++) {
        if (state.currentBatch[i].importRowId == payload.rowId) {
          state.currentBatch[i].isActionUpdated = true;
          break;
        }
      }
    }
    commit("uxModule/setShowLoader", false, {
      root: true
    });
  },
  async sendEmails({ commit, state }, payload) {
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var response = await api.sendEmails(payload);
    commit("uxModule/setShowLoader", false, {
      root: true
    });
    var msg;
    if (response.success == true) {
      msg = "Emails sent!";
    } else {
      msg = "Error sending emails.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true
    });
  },
  async closeBatch({ commit, state }, id) {
    commit("uxModule/setShowLoader", true, {
      root: true
    });
    var response = await api.closeBatch(id);
    if (response == 1) {
      var responseB = await api.getRfaBatches();
      state.allBatches = responseB;
      var i;
      for (i = 0; i < state.allBatches.length; i++) {
        state.allBatches[i].importDate = new Date(
          Date.parse(state.allBatches[i].importDate)
        ).toLocaleDateString("en-US");
      }
    }
    commit("uxModule/setShowLoader", false, {
      root: true
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
