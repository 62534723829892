<template>
  <div>
    <div style="margin-top:50px; margin-left:20px;">
      <v-row>
        <label> Upload the .csv file containing the RFA Import. </label>
      </v-row>
      <v-row>
        <v-col cols="6">
          <template>
            <v-file-input accept=".csv"
                          label="Upload file"
                          v-on:change="fileSelected"
                          color="#1c344b"></v-file-input>
          </template>
        </v-col>

        <v-col cols="2">
          <v-btn small
                 style="background-color: #1c344b; border-radius: 8px;"
                 v-on:click="upload"
                 :disabled="disableButton"
                 dark
                 class="mt-5">Create Batch</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      disableButton: function ()
      {
         var file = this.$store.state.rfaModule.file;
      if (file == null || typeof file === "undefined" || file == "") {
        return true;
      } else {
        return false;
      }
      }
    },
    methods: {
      fileSelected: function (file)
      {
         this.$store.commit("rfaModule/setFile", file);
      },
      create: async function ()
      {
        var uploaded = await this.$store.dispatch("rfaModule/createRfa");
        this.$router.push({ name: 'AllRFABatches' })

      }
    }
  }

</script>
