<template>
  <transition appear name="slide-fade" mode="out-in">
    <v-container class="mt-12" row fluid>
      <v-col cols="1">
        <back-btn
          v-show="type == 'Child'"
          v-on:backClicked="setTypeToParent"
        ></back-btn
      ></v-col>
      <v-row dense>
        <tile-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :type="type"
          v-if="item.onTileGrid"
          v-on:parentTileClicked="setTileList(index)"
          class="mr-10"
        ></tile-item
      ></v-row> </v-container
  ></transition>
</template>

<script>
import BackButton from "./back-btn.vue";
import TileItem from "./tile-item";
import { mapState } from "vuex";
import { routes } from "../../router/routes.js";
export default {
  data() {
    return {
      routes,
      type: "Parent",
      selectedIndex: 0
    };
  },

  components: {
    "tile-item": TileItem,
    "back-btn": BackButton
  },
  //data() {
  //  return {
  //    userApplications: [
  //      {
  //        icon: 'user',
  //        name: 'Messagine',
  //        alerts: 0,
  //      },
  //      {
  //        icon: 'user',
  //        name: 'Messagine',
  //        alerts: 1,
  //      },
  //    ]
  //  }
  //}
  computed: {
    items: function() {
      if (this.type == "Parent") {
        return this.parentTiles;
      } else {
        return this.parentTiles[this.selectedIndex].children;
      }
    },
    parentTiles: function() {
      return this.routes.filter(r => r.onTileGrid == true);
    }
  },
  methods: {
    setTileList(index) {
      console.log("In set tile list index is " + index);
      this.type = "Child";
      this.selectedIndex = index;
    },
    setTypeToParent: function() {
      this.type = "Parent";
    }
  }
};
</script>
