<template>
  <div>
    <v-toolbar flat color="white">
      <v-toolbar-title style="font-size:18px; color:#ee44aa"
        >Import Batch {{ batchId }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <emails-modal></emails-modal>
    </v-toolbar>

    <v-toolbar flat color="white">
      <v-toolbar-title style="font-size:18px; color:#ee44aa"
        >Filters</v-toolbar-title
      >
      <v-btn icon v-on:click="toggleShowFilters"
        ><v-icon small>{{ expansionIcon }}</v-icon></v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        text
        x-small
        color="#0000EE"
        class="pt-1"
        v-show="showFilters"
        @click="clearAllFilters"
      >
        Clear Filters
      </v-btn>
    </v-toolbar>
    <div v-show="showFilters" class="mb-15">
      <v-row>
        <v-col cols="2">
          <v-autocomplete
            v-model="providerFilter"
            :items="providerOptions"
            label="Provider"
            style="font-size:10px;"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="schoolFilter"
            :items="schoolOptions"
            label="School"
            clearable
            style="font-size:10px;"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="gradeFilter"
            :items="grades"
            label="Grade"
            clearable
            style="font-size:10px;"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="languageFilter"
            :items="languages"
            label="Language"
            clearable
            style="font-size:10px;"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="assessmentFilter"
            :items="assessmentOptions"
            label="Assessment type"
            clearable
            style="font-size:10px;"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-switch
            v-model="unassignedOnly"
            label="Hide assigned"
            color="primary"
            hide-details
          ></v-switch>
        </v-col>
        <!--<v-col cols="1">
    <v-switch v-model="missingConsentOnly"
              label="Show missing consent"
              color="primary"
              hide-details></v-switch>
  </v-col>
  <v-col cols="1">
    <v-switch v-model="blockedProvidersOnly"
              label="Show blocked providers"
              color="primary"
              hide-details></v-switch>
  </v-col>-->
      </v-row>

      <v-divider class="mt-16"></v-divider>
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="batch"
      :single-expand="true"
      item-key="importRowId"
      :expanded.sync="expanded"
      show-expand
      multi-sort
      id="data"
    >
      <template v-slot:item.adminDBN="{ item }">
        <span>{{ toTitleCase(item.adminDBN) }}</span>
      </template>

      <template v-slot:item.grade="{ item }">
        <span>{{ item.grade }}</span>
      </template>

      <template v-slot:item.assessmentType="{ item }">
        <span>{{ item.assessmentType }}</span>
      </template>

      <template v-slot:item.languageOfAssessment="{ item }">
        <span>{{ item.languageOfAssessment }}</span>
      </template>

      <template v-slot:item.provider="{ item }">
        <v-autocomplete
          clearable
          v-model="item.providerId"
          :items="getProviderOptions(item.assessmentType)"
          v-on:change="
            updateProvider({ id: item.importRowId, value: item.providerId })
          "
          style="max-width:300px;"
        >
          <template v-slot:selection="data">
            <span
              v-if="data.item.isBlocked"
              class="mt-0 mb-0 pt-0 pb-0"
              style="color:darkgrey;"
              x-small
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.text }}
            </span>
            <span
              v-else
              style="color:black; "
              class="mt-0 mb-0 pt-0 pb-0"
              x-small
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.text }}
            </span>
          </template>

          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title
                style="color:darkgrey;"
                v-html="data.item.text"
                v-if="data.item.isBlocked"
              ></v-list-item-title>
              <v-list-item-title
                v-else
                style="color:black;"
                v-html="data.item.text"
              ></v-list-item-title>
            </v-list-item-content>
            <!--<v-list-item-content v-if="!data.isBlocked" v-text="data.text" style="color:red;"><span>{{data.text}}</span></v-list-item-content>-->
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.isVAction">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="grey" v-bind="attrs" v-on="on"
                ><v-icon>mdi-close-box</v-icon></v-btn
              >
            </template>
            <span>Action Not Accepted</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <v-simple-checkbox
                v-model="item.hasConsent"
                v-on:input="
                  hasConsentChanged({
                    id: item.importRowId,
                    value: item.hasConsent
                  })
                "
              >
              </v-simple-checkbox>
              <span style="font-size:10px; color:darkgray;">Consent</span>
            </v-col>
            <v-col>
              <v-btn
                v-if="!item.isActionUpdated"
                :disabled="
                  item.providerId == null ||
                    item.providerId == '' ||
                    item.isProviderBlocked ||
                    !item.hasConsent
                "
                icon
                v-on:click="
                  updateInDb({
                    rowId: item.importRowId,
                    actionId: item.actionId,
                    providerId: item.providerId,
                    initial: userInitial
                  })
                "
                class="mt-6"
                x-small
                color="primary"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
              <v-btn
                v-else
                :disabled="
                  item.providerId == null ||
                    item.providerId == '' ||
                    item.isProviderBlocked ||
                    !item.hasConsent
                "
                icon
                v-on:click="
                  updateInDb({
                    rowId: item.importRowId,
                    actionId: item.actionId,
                    providerId: item.providerId,
                    initial: userInitial
                  })
                "
                x-small
                color="secondary"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </v-col>
            <v-col>
              <v-tooltip bottom v-if="!item.isVAction">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on:click="
                      updateVAction({
                        rowId: item.importRowId,
                        actionId: item.actionId
                      })
                    "
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-close-box</v-icon></v-btn
                  >
                </template>
                <span>Mark as 'V' Action</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="5">
              <table class="mt-8 ml-8 mb-8">
                <tbody>
                  <tr>
                    <td class="labelCell">Action Id:</td>
                    <td class="valueCell">{{ item.actionId }}</td>
                  </tr>
                  <tr>
                    <td class="labelCell">Date of Birth:</td>
                    <td class="valueCell">{{ item.dateOfBirth }}</td>
                  </tr>
                  <tr>
                    <td class="labelCell">School:</td>
                    <td class="valueCell">{{ item.schoolName }}</td>
                  </tr>

                  <tr>
                    <td class="labelCell">Action Taken:</td>
                    <td class="valueCell">{{ item.actionTaken }}</td>
                  </tr>
                  <tr>
                    <td class="labelCell">Requested By:</td>
                    <td class="valueCell">{{ item.requestedBy }}</td>
                  </tr>
                  <tr>
                    <td class="labelCell">Evaluation Due Date:</td>
                    <td class="valueCell">{{ item.evalDate }}</td>
                  </tr>
                  <tr>
                    <td class="labelCell">Evaluation Report Status:</td>
                    <td class="valueCell">
                      {{ item.evaluationReportStatus }}
                    </td>
                  </tr>
                  <tr>
                    <td class="labelCell">System Status:</td>
                    <td class="valueCell">{{ item.status }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>

            <v-col :cols="getCommentsColumns(item)">
              <v-textarea
                v-model="item.comments"
                class="mr-8 mb-6 mt-8"
                rows="5"
                v-on:click:clearable="
                  updateComment({
                    id: item.importRowId,
                    value: item.comments
                  })
                "
                outlined
                label="Comments"
                clearable
                @change="
                  debounceUpdateComment({
                    id: item.importRowId,
                    value: item.comments
                  })
                "
              >
                <!--<template v-slot:append>
                  <v-btn small icon v-on:click="updateComment({id:item.importRowId, value: item.comments})" class="pb-1 mt-0"><v-icon color="primary">mdi-content-save</v-icon></v-btn>
                </template>-->
              </v-textarea>
            </v-col>
            <v-col cols="2" v-if="item.evalDate == 'Invalid Date'">
              <form-field
                :field="evalDueDateField"
                v-on:fieldChange="
                  updateEvalDueDate({
                    rowId: item.importRowId,
                    actionId: item.actionId,
                    evalDueDate: $event
                  })
                "
              ></form-field>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { FieldTypes } from "@/objs/Shared/FieldTypes";
import FormField from "../../objs/Shared/FormField";
import EmailsModal from "./emails-modal";
export default {
  props: ["batchId"],
  components: {
    "emails-modal": EmailsModal
  },
  data: function() {
    return {
      debounce: null,
      expanded: [],

      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "ID",
          value: "studentId",
          sortable: true
        },
        {
          text: "Name",
          value: "studentName",
          sortable: true
        },
        {
          text: "DBN",
          value: "adminDBN",
          sortable: true
        },
        {
          text: "Grade",
          value: "grade",
          sortable: true
        },

        {
          text: "Eval",
          value: "assessmentType",
          sortable: true
        },
        {
          text: "Language",
          value: "languageOfAssessment",
          sortable: true
        },
        {
          text: "Provider",
          value: "provider",
          sortable: true
        },

        {
          text: "Actions",
          value: "actions",
          sortable: true
        }
      ],
      providerFilter: "",
      schoolFilter: "",
      gradeFilter: "",
      assessmentFilter: "",
      languageFilter: "",
      unassignedOnly: false,
      blockedProvidersOnly: false,
      missingConsentOnly: false,
      grades: [
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12"
      ],
      showFilters: false,
      languages: [
        "English",
        "French",
        "Georgian",
        "German",
        "Haitian",
        "Hebrew",
        "Hungarian",
        "Indonesian",
        "Italian",
        "Korean",
        "Made Up Language",
        "Mandarin",
        "Persian",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Russian",
        "Sign Language",
        "Spanish",
        "Tadzhiks",
        "Tagalog",
        "Turkish",
        "Urdu",
        "Yiddish"
      ]
    };
  },

  mounted: function() {
    this.$store.dispatch("rfaModule/getBatches");
  },
  computed: {
    evalDueDateField: function() {
      return new FormField({
        tier: -1,
        componentName: FieldTypes.DATE_PICKER,
        label: "Enter Evaluation Due Date"
      });
    },
    userInitial: function() {
      return (
        this.$store.state.securityModule.dashboardUser.firstName.charAt(0) +
        this.$store.state.securityModule.dashboardUser.lastName.charAt(0)
      );
    },
    expansionIcon() {
      if (this.showFilters) {
        return "mdi-chevron-up";
      } else {
        return "mdi-chevron-down";
      }
    },
    schoolOptions() {
      return this.$store.state.rfaModule.schoolOptions;
    },
    assessmentOptions() {
      return this.$store.state.rfaModule.assessmentOptions;
    },
    providerOptions: function() {
      var providers = this.$store.state.rfaModule.providers;
      var options = [];
      var i;
      for (i = 0; i < providers.length; i++) {
        options.push({
          text: providers[i].displayAs,
          value: providers[i].providerId,
          isBlocked: providers[i].isBlocked
        });
      }
      return options;
    },
    batch: function() {
      var batchRows = this.$store.getters["rfaModule/rowsToAssign"];
      if (this.providerFilter != "" && this.providerFilter != null) {
        batchRows = batchRows.filter(t => t.providerId == this.providerFilter);
      }
      if (this.gradeFilter != "" && this.gradeFilter != null) {
        batchRows = batchRows.filter(t => t.grade == this.gradeFilter);
      }
      if (this.schoolFilter != "" && this.schoolFilter != null) {
        batchRows = batchRows.filter(t => t.schoolName == this.schoolFilter);
      }
      if (this.assessmentFilter != "" && this.assessmentFilter != null) {
        batchRows = batchRows.filter(
          t => t.assessmentType == this.assessmentFilter
        );
      }
      if (this.languageFilter != "" && this.languageFilter != null) {
        batchRows = batchRows.filter(
          t => t.languageOfAssessment == this.languageFilter
        );
      }
      if (this.unassignedOnly) {
        batchRows = batchRows.filter(
          t => t.providerId == null || t.providerId == ""
        );
      }
      if (this.blockedProvidersOnly) {
        batchRows = batchRows.filter(t => t.isProviderBlocked);
      }
      if (this.missingConsentOnly) {
        batchRows = batchRows.filter(t => t.hasConsent == false);
      }
      return batchRows;
    }
  },

  methods: {
    getCommentsColumns: function(item) {
      if (item.evalDate == "Invalid Date") {
        return 5;
      } else {
        return 7;
      }
    },
    toggleShowFilters: function() {
      this.showFilters = !this.showFilters;
    },
    clearAllFilters() {
      this.providerFilter = null;
      this.gradeFilter = null;
      this.assessmentFilter = null;
      this.schoolFilter = null;
      this.unassignedOnly = false;
      this.missingConsentOnly = false;
      this.blockedProvidersOnly = false;
    },
    isDisabled(item) {
      var providerBlocked;
    },

    getProviderOptions: function(assessmentType) {
      var providers = this.$store.state.rfaModule.providers;
      var options = [];
      var i;
      for (i = 0; i < providers.length; i++) {
        if (providers[i].assessmentType == assessmentType) {
          options.push({
            text: providers[i].displayAs,
            value: providers[i].providerId,
            isBlocked: providers[i].isBlocked
          });
        }
      }
      return options;
    },
    toTitleCase(str) {
      var st = str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      return st.substring(0, 45);
    },

    async goToBatch(id) {
      await this.$store.dispatch("rfaModule/getBatchRows", id);
      await this.$store.dispatch("rfaModule/getBatchProviders", id);
      this.$router.push({
        name: "CurrentBatch",
        params: {
          batchId: id
        }
      });
    },
    async updateVAction(payload) {
      console.log("Update this item, marking as v ");
      var response = await this.$store.dispatch(
        "rfaModule/updateVAction",
        payload
      );
    },
    async updateInDb(payload) {
      //if(item.evalDate == null){please input eval date and proceed to save.})
      console.log("Update this item ");
      var response = await this.$store.dispatch(
        "rfaModule/updateAction",
        payload
      );
    },
    async updateEvalDueDate(payload) {
      var response = await this.$store.dispatch(
        "rfaModule/updateEvalDueDate",
        payload
      );
    },
    async updateProvider(payload) {
      var response = await this.$store.dispatch(
        "rfaModule/updateProvider",
        payload
      );
      console.log("Updating provider");
      console.log(response);
    },
    async updateComment(payload) {
      console.log("Update comment getting called");
      console.log(payload);
      var response = await this.$store.dispatch(
        "rfaModule/updateComment",
        payload
      );
    },
    async debounceUpdateComment(payload) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        var response = await this.$store.dispatch(
          "rfaModule/updateComment",
          payload
        );
      }, 300);
    },
    async hasConsentChanged(payload) {
      console.log("Update comment");
      var response = await this.$store.dispatch(
        "rfaModule/updateHasConsent",
        payload
      );
    }
  }
};
</script>

<style>
.v-data-table span {
  font-size: 16px;
}
</style>
