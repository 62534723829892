<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-on:click="open" v-bind="attrs" v-on="on">
          Email Providers
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Email Providers
        </v-card-title>

        <v-card-text>
          <p style="margin-top:20px;">Which provider do you want to email?</p>
          <v-select
            :items="providers"
            item-text="displayAs"
            item-value="providerId"
            v-model="provider"
          ></v-select>
          <div v-if="showAssignments">
            <p>Which assignments should be included in the email?</p>
            <v-select
              chips
              deletable-chips
              multiple
              :items="rows"
              v-model="rowIds"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="rowIds.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="send">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      provider: "",
      rowIds: []
    };
  },
  watch: {
    provider: function(newVal) {
      this.rowIds = [];
    }
  },
  computed: {
    selectAll: function() {
      return this.rowIds.length == this.rows.length;
    },
    someSelected: function() {
      return this.rowIds.length > 0 && !this.selectAll;
    },
    icon: function() {
      if (this.selectAll) {
        return "mdi-close-box";
      }
      if (this.someSelected) {
        return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    showAssignments() {
      return this.provider != "";
    },
    providers() {
      return this.$store.state.rfaModule.providersForBatch;
    },
    rows() {
      var r = [];
      var rows = this.$store.getters["rfaModule/rowsForProvider"](
        this.provider
      );
      var i;
      for (i = 0; i < rows.length; i++) {
        r.push({
          text: rows[i].lastName + " " + rows[i].firstName,
          value: rows[i].importRowId
        });
      }
      return r;
    }
  },
  methods: {
    cancel() {},
    async send() {
      await this.$store.dispatch("rfaModule/sendEmails", {
        providerId: this.provider,
        rowIds: this.rowIds
      });
      this.dialog = false;
    },
    async open() {
      this.dialog = true;
      await this.$store.dispatch("rfaModule/getDistinctBatchProviders");
      this.provider = "";
      this.rowIds = [];
    },
    toggle() {
      if (this.selectAll) {
        this.rowIds = [];
      } else {
        this.rowIds = [];
        var i;
        for (i = 0; i < this.rows.length; i++) {
          this.rowIds.push(this.rows[i].value);
        }
      }
    }
  }
};
</script>
