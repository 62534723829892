import Callback from "../components/Security/callback";
import Home from "../components/HomePage/tile-grid.vue";

import RFAImportSection from "../components/RFAImport/rfa-import-section";
import NewRFABatch from "../components/RFAImport/new-rfa-batch";
import AllBatches from "../components/RFAImport/rfa-batches";
import CurrentBatch from "../components/RFAImport/current-batch";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    meta: {
      requiresAuth: false
    }
  },

  {
    name: "RFAImport",
    path: "/rfa",
    icon: "list",
    title: "RFA Import",
    component: RFAImportSection,
    onNavBar: true,
    onTileGrid: true,
    meta: {
      requiresAuth: true
    },
    children: [
      //{
      //  name: "NewRFABatch",
      //  path: "/rfa/new",
      //  icon: "file-invoice-dollar",
      //  title: "New Import",
      //  component: NewRFABatch,
      //  onNavBar: true,
      //  meta: {
      //    requiresAuth: false
      //  }
      //},
      {
        name: "AllRFABatches",
        path: "/rfa/batches",
        icon: "list",
        title: "All Imports",
        component: AllBatches,
        onNavBar: true,
        onTileGrid: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: "CurrentBatch",
        path: "/rfa/current-batch:batchId",
        icon: "file-invoice-dollar",
        title: "Current Batch",
        component: CurrentBatch,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false
  }
];
