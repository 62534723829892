<template>
  <div>
    <v-toolbar flat color="white">
      <v-toolbar-title style="font-size:18px; color:#ee44aa"
        >Import Batches</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <reports></reports>
    </v-toolbar>

    <v-data-table :headers="headers" :items="sortedBatches">
      <template v-slot:item.actions="{ item }">
        <v-btn
          text
          x-small
          color="indigo"
          v-on:click="goToBatch(item.importBatchId)"
          >Process</v-btn
        >
        <v-btn
          text
          x-small
          color="indigo"
          v-on:click="closeBatch(item.importBatchId)"
          >Close</v-btn
        >
      </template>
    </v-data-table>

    <v-row>
      <v-col cols="5">
        <div></div>
      </v-col>
      <v-col cols="2">
        <v-btn
          small
          style="background-color: #1c344b; border-radius: 8px;"
          v-on:click="create"
          dark
          class="mt-5"
          >Create New Batch</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Reports from "./reports-modal";
export default {
  components: {
    reports: Reports
  },
  data: function() {
    return {
      headers: [
        {
          text: "Import Batch Id",
          value: "importBatchId",
          sortable: false
        },
        {
          text: "Date of Import",
          value: "importDate",
          sortable: true
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start"
        }
      ]
    };
  },
  mounted: function() {
    this.$store.dispatch("rfaModule/getBatches");
    this.$store.dispatch("rfaModule/getOptions");
  },
  computed: {
    batches: function() {
      return this.$store.state.rfaModule.allBatches;
    },
    sortedBatches: function() {
      return this.batches.sort(function(a, b) {
        return b.importBatchId < a.importBatchId
          ? -1
          : b.importBatchId > a.importBatchId
          ? 1
          : 0;
      });
    }
  },
  methods: {
    create: async function() {
      var uploaded = await this.$store.dispatch("rfaModule/createRfa");
      this.$router.push({ name: "AllRFABatches" });
    },
    async closeBatch(id) {
      await this.$store.dispatch("rfaModule/closeBatch", id);
    },

    async goToBatch(id) {
      await this.$store.dispatch("rfaModule/getBatchRows", id);
      await this.$store.dispatch("rfaModule/getBatchProviders", id);
      this.$router.push({
        name: "CurrentBatch",
        params: {
          batchId: id
        }
      });
    }
  }
};
</script>
