
<template>
  <div class="text-center">
    <v-dialog v-model="dialog"
              width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary"
               class="mt-3 text--white"
               dark
              
               v-on:click="open"
               v-bind="attrs"
               v-on="on"
               small>
        
          Reports
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Download Reports
        </v-card-title>

        <v-card-text>

          <v-menu v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="startDate"
                            label="Report start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="startDate"
                           @input="menu2 = false"></v-date-picker>
          </v-menu>
          <v-row>
            <v-col>
              <v-btn color="primary"
                     text
                     @click="blockedProviders">
                Blocked Providers
              </v-btn>
            </v-col>

            <v-col>
              <v-btn color="primary"
                     text
                     @click="missingConsent">
                Missing Consent
              </v-btn>
            </v-col>
          </v-row>
        
        </v-card-text>

       

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="done">
            Done
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data()
    {
      return {
        dialog: false,
        startDate: new Date().toISOString().substr(0, 10),
        menu2: false
      }
    },
   
    computed: {
    
    },
    methods: {
      done()
      {
        this.startDate = new Date().toISOString().substr(0, 10);
        this.dialog = false;
      },
      cancel()
      {

      },
      async blockedProviders()
      {
                window.location = 'https://api.omniuserportal.com/api/rFAImport/getBlockedProvidersReport?startDate=' + this.startDate;

      },
      async missingConsent()
      {
        window.location = 'https://api.omniuserportal.com/api/rFAImport/getMissingConsentReport?startDate=' + this.startDate;

      },
      async open()
      {
        this.dialog = true;

      }
    }
  }
</script>
