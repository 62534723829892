<template>
  <v-col cols="12" sm="3">
    <v-item>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :style="`border:2px solid ${color};`"
            :elevation="hover ? 24 : 2"
            @click="onTileSelect"
          >
            <v-container class="vertical-center">
              <v-row align="end" :style="`height:${cardHeight}px;`">
                <v-col class="text-center">
                  <icon
                    :color="color"
                    style="tile-icon"
                    class="fa-2x"
                    :icon="item.icon"
                  />
                </v-col>
              </v-row>

              <v-row align="start" :style="`height:${cardHeight}px;`">
                <v-col class="text-center" cols="12">
                  <span
                    class="font-weight-light"
                    v-bind:style="{ color: color }"
                  >
                    {{ item.title }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!--</v-badge>-->
        </template>
      </v-hover>
    </v-item></v-col
  ></template
>
<script>
export default {
  props: ["item", "type"],
  computed: {
    color: function() {
      return "#1c344b";
    },
    windowWidth: function() {
      return window.innerWidth;
    }
  },
  data() {
    return {
      cardHeight: 0
    };
  },
  created() {
    window.addEventListener("resize", this.reSizeWindow);
    this.reSizeWindow();
  },
  destroyed() {
    window.addEventListener("resize", this.reSizeWindow);
  },
  watch: {
    windowWidth: function() {
      this.reSizeWindow();
    }
  },
  methods: {
    onTileSelect: function() {
      if (this.type == "Parent") {
        this.$emit("parentTileClicked");
      } else {
        this.$router.push({
          name: this.item.name
        });
      }
    },

    reSizeWindow() {
      if (window.innerWidth > 600) {
        this.cardHeight = window.innerWidth / 12;
      } else {
        this.cardHeight = 100;
        console.log("hererere ");
      }
    }
  }
};
</script>

<style>
.verticle-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
