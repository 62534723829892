<template>
  <v-btn large outlined color="#1D3557" dark v-on:click="$emit('backClicked')">
    <icon icon="arrow-left" class="mr-2"></icon>Back
  </v-btn>
</template>

<script>
export default {
  methods: {
    backClicked: function() {
      this.setTileBack();
    }
  }
};
</script>
